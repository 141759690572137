import React, { Component } from "react"
import { BsArrowRight } from "react-icons/bs"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import "../assets/css/contact.scss"
export default class contact extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <Layout>
        <main id="contact" className="page">
          <div className="content">
            <article>
              <h5>- Contact</h5>
              <h4>
                Got a project? <br /> Let's talk
              </h4>
              <p>
                There is no one who loves their first website, who seeks after
                it and wants to have it, simply call me...
              </p>
              {/* <p>There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...</p> */}

              <a className="mailto" href="mailto:anggah.net@gmail.com">
                anggah.net@gmail.com <BsArrowRight />
              </a>
            </article>
            <div className="content-form">
              <form
                action="https://formspree.io/f/mvodrzae"
                method="POST"
                encType="multipart/form-data"
              >
                <div className="form-default">
                  <label htmlFor="name">What's your name</label>
                  <input name="name" type="text" required />
                </div>
                <div className="form-default">
                  <label htmlFor="email">Your fancy email</label>
                  <input id="email" name="email" type="email" required />
                </div>
                <div className="form-default">
                  <label htmlFor="message">Your message</label>
                  <textarea id="message" name="message" type="text" required />
                </div>
                {/* <div className='form-default'>
                                <label htmlFor='project'>Tell me your detail project <BsPaperclip /></label>
                                <input id='project' name='file' type='file' hidden onChange={(e) => this.handleChange(e.target.files[0])} />

                                {files}
                            </div> */}
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export const Head = props => (
  <Seo
    title="Contact | Angga Hermawan"
    description={`Looking for your own website? as a fullstack developer if I can be of assistance, please do not hesitate to contact Angga Hermawan or If there is anything else you need, please let me know.`}
    pathname={props.location?.pathname}
  />
)
